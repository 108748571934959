function isCanAccess(item, user) {
  if (user) {
    if (user.id == 1 || user.id == 7 || user.role.superadmin == true || user.role.superadmin == 1) {
      return true
    } else {
      if (user.role) {
        if (user.role.assignments) {
          if (user.role.assignments[item[0]][item[1]]) {
            if (user.role.assignments[item[0]][item[1]]['index']) {
              if (user.role.assignments[item[0]][item[1]]['index'] == 1) {
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false
}

function isSuperAdmin(user) {
  if (user) {
    if (user.id == 1 || user.id == 7 || user.role.superadmin == true || user.role.superadmin == 1) {
      return [
        {
          _name: "CSidebarNavDropdown",
          name: "Management User",
          route: "/management-users/users",
          icon: "cil-cursor",
          items: [
            {
              name: "Users",
              to: "/management-users/users",
            },
            {
              name: "Role",
              to: "/management-users/roles",
            },
          ],
        }
      ];
    }
  }
  return false;
}

function dashboard(user) {
  let dashboard = [
    {
      _name: 'CSidebarNavItem',
      name: 'Home',
      to: '/dashboard',
      icon: 'cil-speedometer',
      badge: {
        color: 'primary',
        text: 'NEW'
      }
    }
  ];
  if (user) {
    if (user.id == 1 || user.id == 7 || user.role.superadmin == true || user.role.superadmin == 1) {
      return dashboard;
    }
  }
  return false;
}

function admin(user) {
  const item = []
  if (isCanAccess(['admin', 'kualifikasi_jabatan_struktural'], user))
    item.push(
      {
        name: "Kualifikasi Jabatan Struktural",
        to: "/admin/kualifikasi-jabatan-struktural",
      })
  if (isCanAccess(['admin', 'abk_jabatan_umum'], user))
    item.push(
      {
        name: "ABK Jabatan Umum",
        to: "/admin/abk-jabatan-umum",
      })
  if (isCanAccess(['admin', 'informasi_jabatan_umum'], user))
    item.push(
      {
        name: "Informasi Jabatan Umum",
        to: "/admin/informasi-jabatan-umum",
      })
  if (isCanAccess(['admin', 'abk_jabatan_fungsional'], user))
    item.push(
      {
        name: "ABK Jabatan Fungsional",
        to: "/admin/abk-jabatan-functional",
      })
  if (isCanAccess(['admin', 'informasi_jabatan_functional'], user))
    item.push(
      {
        name: "Informasi Jabatan Fungsional",
        to: "/admin/informasi-jabatan-functional",
      })

  return item.length > 0 ? [
    {
      _name: "CSidebarNavDropdown",
      name: "Admin",
      route: "/admin",
      icon: "cil-puzzle",
      items: item,
    },] : false
}

function layanan(user) {
  const item = [];
  if (isCanAccess(['layanan', 'jenis_layanan'], user)) {
    item.push(
      {
        name: "Jenis Layanan",
        to: "/layanan/jenis-layanan",
      })
  }
  if (isCanAccess(['layanan', 'persyaratan_layanan'], user)) {
    item.push(
      {
        name: "Persyaratan Pelayanan",
        to: "/layanan/persyaratan-pelayanan",
      })
  }
  if (isCanAccess(['layanan', 'atur_masa_persiapan_pensiun'], user)) {
    item.push(
      {
        name: "Atur Masa Persiapan Pensiun",
        to: "/layanan/atur-masa-persiapan-pensiun",
      })
  }


  return item.length > 0 ? [
    {
      _name: "CSidebarNavDropdown",
      name: "Layanan",
      route: "/layanan",
      icon: "cil-puzzle",
      items: item,
    },] : false
}

function usulanLayanan(user) {
  const item = [];

  // if (isCanAccess(['usulan_layanan', 'usulan_kenaikan_pangkat'], user))
  //   item.push(
  //     {
  //       name: "Usulan Kenaikan Pangkat",
  //       to: "/usulan-layanan/usulan-kenaikan-pangkat",
  //     }
  //   )

  // if (isCanAccess(['usulan_layanan', 'usulan_mutasi_masuk'], user))
  //   item.push(
  //     {
  //       name: 'Usulan Mutasi Masuk',
  //       to: '/usulan-layanan/usulan-mutasi-masuk'
  //     },
  //   )

  // if (isCanAccess(['usulan_layanan', 'usulan_mutasi_keluar'], user))
  //   item.push(
  //     {
  //       name: "Usulan Mutasi Keluar",
  //       to: "/usulan-layanan/usulan-mutasi-keluar",
  //     },
  //   )
  
  if (isCanAccess(['usulan_layanan', 'usulan_penerbitan_sk_pensiun'], user)) {
    item.push(
      {
        name: "Usulan Penerbitan SK Pensiun",
        to: "/usulan-layanan/usulan-penerbitan-sk-pensiun",
      },
    )
  }

  if (isCanAccess(['usulan_layanan', 'usulan_skumptk'], user)) {
    item.push(
      {
        name: "Usulan SKUMPTK",
        to: "/usulan-layanan/usulan-skumptk",
      },
      )
  }

  // if (isCanAccess(['usulan_layanan', 'pencantuman_gelar'], user))
  //   item.push(
  //     {
  //       name: 'Pencantuman Gelar',
  //       to: '/usulan-layanan/pencantuman-gelar'
  //     },
  //   )

  // if (isCanAccess(['usulan_layanan', 'sk_kenaikan_pangkat'], user))
  //   item.push(
  //     {
  //       name: 'P&P SK Kenaikan Pangkat',
  //       to: '/usulan-layanan/perbaikan-pergantian'
  //     },
  //   )

  // if (isCanAccess(['usulan_layanan', 'sk_pengangkatan'], user))
  //   item.push(
  //     {
  //       name: 'P&P SK Pengangkatan',
  //       to: '/usulan-layanan/perbaikan-pergantian-pengangkatan'
  //     },
  //   )


  return item.length > 0 ? [
    {
      _name: "CSidebarNavDropdown",
      name: "Usulan Layanan",
      route: "/usulan-layanan",
      icon: "cil-pencil",
      items: item,
    },] : false
}

function disposisiLayanan(user) {
  const item = [];

  if (isCanAccess(['disposisi_layanan', 'disposisi_penerbitan_sk_pensiun'], user)) {
    item.push(
      {
        name: "Penerbitan SK Pensiun",
        to: "/disposisi-layanan/disposisi-penerbitan-sk-pensiun",
      }
      )
  }
  if (isCanAccess(['disposisi_layanan', 'disposisi_skumptk'], user)) {
    item.push(
      {
        name: "SKUMPTK",
        to: "/disposisi-layanan/disposisi-skumptk",
      }
    )
  }

  return item.length > 0 ? [
    {
      _name: "CSidebarNavDropdown",
      name: "Disposisi Layanan",
      route: "/disposisi-layanan",
      icon: "cil-arrow-right",
      items: item,
    },] : false
}

function verifikasiLayanan(user) {
  const item = [];
  // if (isCanAccess(['verifikasi_layanan', 'kenaikan_pangkat'], user))
  //   item.push(
  //     {
  //       name: 'Kenaikan Pangkat',
  //       to: '/verifikasi-layanan/verifikasi-kenaikan-pangkat'
  //     })

  // if (isCanAccess(['verifikasi_layanan', 'usulan_mutasi_masuk'], user))
  //   item.push(
  //     {
  //       name: 'Mutasi Masuk',
  //       to: '/verifikasi-layanan/verifikasi-usulan-mutasi-masuk'
  //     })

  // if (isCanAccess(['verifikasi_layanan', 'pencantuman_gelar'], user))
  //   item.push(
  //     {
  //       name: 'Pencantuman Gelar',
  //       to: '/verifikasi-layanan/verifikasi-pencantuman-gelar'
  //     })

  // if (isCanAccess(['verifikasi_layanan', 'pnp_sk_kenaikan_pangkat'], user))
  //   item.push(
  //     {
  //       name: 'P&P SK Kenaikan Pangkat',
  //       to: '/verifikasi-layanan/verifikasi-perbaikan-pergantian'
  //     })

  // if (isCanAccess(['verifikasi_layanan', 'pnp_sk_pengangkatan'], user))
  //   item.push({
  //     name: 'P&P SK Pengangkatan',
  //     to: '/verifikasi-layanan/verifikasi-perbaikan-pergantian-pengangkatan'
  //   })

  // if (isCanAccess(['verifikasi_layanan', 'usulan_mutasi_masuk'], user))
  //   item.push({
  //     name: "Mutasi Keluar",
  //     to: "/verifikasi-layanan/verifikasi-mutasi-keluar",
  //   })

    if (isCanAccess(['verifikasi_layanan', 'verifikasi_penerbitan_sk_pensiun_i'], user)) {
      item.push({
        name: "Penerbitan SK Pensiun I",
        to: "/verifikasi-layanan/verifikasi-penerbitan-sk-pensiun",
      })
    }

    if (isCanAccess(['verifikasi_layanan', 'verifikasi_penerbitan_sk_pensiun_ii'], user)) {
      item.push({
        name: "Penerbitan SK Pensiun II",
        to: "/verifikasi-layanan/verifikasi-penerbitan-sk-pensiun-ii",
      })
    }

    if (isCanAccess(['verifikasi_layanan', 'verifikasi_skumptk_i'], user)) {
      item.push({
        name: "SKUMPTK I",
        to: "/verifikasi-layanan/verifikasi-skumptk",
      })
    }

    if (isCanAccess(['verifikasi_layanan', 'verifikasi_skumptk_ii'], user)) {
      item.push({
        name: "SKUMPTK II",
        to: "/verifikasi-layanan/verifikasi-skumptk-ii",
      })
    }

  return item.length > 0 ? [{
    _name: "CSidebarNavDropdown",
    name: "Verifikasi Layanan",
    route: "/verifikasi-layanan",
    icon: "cil-check",
    items: item
  }] : false
}

function laporan(user) {
  const item = [];
  if (isCanAccess(['laporan', 'per-opd-skpd'], user))
    item.push(
      {
        name: 'Per OPD/SKPD',
        to: '/laporan/per-opd-skpd'
      })

  if (isCanAccess(['laporan', 'per-eselon'], user))
    item.push(
      {
        name: 'Per Eselon',
        to: '/laporan/per-eselon'
      })

  if (isCanAccess(['laporan', 'per-jenis-kelamin'], user))
    item.push(
      {
        name: 'Per Jenis Kelamin',
        to: '/laporan/per-jenis-kelamin'
      })

  if (isCanAccess(['laporan', 'per-golongan'], user))
    item.push(
      {
        name: 'Per Golongan',
        to: '/laporan/per-golongan'
      })

  if (isCanAccess(['laporan', 'per-pendidikan'], user))
    item.push(
      {
        name: 'Per Pendidikan',
        to: '/laporan/per-pendidikan'
      })

  return item.length > 0 ? [{
    _name: "CSidebarNavDropdown",
    name: "Laporan",
    route: "/laporan",
    icon: "cil-pencil",
    items: item
  }] : false
}

function pengesahanLayanan(user) {
  const item = [];

    if (isCanAccess(['pengesahan_layanan', 'pengesahan_penerbitan_sk_pensiun'], user)) {
      item.push({
        name: "Penerbitan SK Pensiun",
        to: "/pengesahan-layanan/pengesahan-penerbitan-sk-pensiun",
      })
    }

    if (isCanAccess(['pengesahan_layanan', 'pengesahan_skumptk'], user)) {
      item.push({
        name: "SKUMPTK",
        to: "/pengesahan-layanan/pengesahan-skumptk",
      })
    }

  return item.length > 0 ? [{
    _name: "CSidebarNavDropdown",
    name: "Pengesahan Layanan",
    route: "/pengesahan-layanan",
    icon: "cil-check-circle",
    items: item
  }] : false
}

function rekapitulasiLayanan(user) {
  const item = [];

  if (isCanAccess(['rekapitulasi_layanan', 'rekapitulasi_penerbitan_sk_pensiun'], user)){
    item.push({
      name: "Penerbitan SK Pensiun",
      to: "/rekapitulasi-layanan/rekapitulasi-usulan-penerbitan-sk-pensiun",
    })
  }

  return item.length > 0 ? [{
    _name: "CSidebarNavDropdown",
    name: "Rekapitulasi Layanan",
    route: "/rekapitulasi-layanan",
    icon: "cil-laptop",
    items: item
  }] : false
}

async function menu(user) {
  const _children = [
    {
      _name: 'CSidebarNavItem',
      name: 'Home',
      to: '/dashboard',
      icon: 'cil-speedometer',
      badge: {
        color: 'primary',
        text: 'NEW'
      }
    },

  ]

  // HIDE menu task https://gitlab.com/nusateknoglobal/purwakarta-project/pojok-aparatur/layanan-be/-/issues/3
  // if (isCanAccess(['uji_kompetensi', null], user))
  //   _children.push(
  //     {
  //       _name: "CSidebarNavItem",
  //       name: "Uji Kompentensi",
  //       to: "/uji-kompetensi",
  //       icon: "cil-pencil",
  //     })

  // if (isCanAccess(['monitoring_jabatan_struktural', null], user))
  //   _children.push(
  //     {
  //       _name: "CSidebarNavItem",
  //       name: "Monitoring Jabatan Struktural",
  //       to: "/monitoring-jabatan-struktural",
  //       icon: "cil-cursor",
  //     })

  // if (isCanAccess(['monitoring_jabatan_fungsional', null], user))
  //   _children.push(
  //     {
  //       _name: "CSidebarNavItem",
  //       name: "Monitoring Jabatan Fungsional",
  //       to: "/monitoring-jabatan-fungsional",
  //       icon: "cil-drop",
  //     })

  // if (isCanAccess(['monitoring_jabatan_umum', null], user))
  //   _children.push(
  //     {
  //       _name: "CSidebarNavItem",
  //       name: "Monitoring Jabatan Umum",
  //       to: "/monitoring-jabatan-umum",
  //       icon: "cil-cursor",
  //     })

  // let adminMenu = admin(user)
  // if (adminMenu) {
  //   _children.push(adminMenu[0])
  // }

  // let dashboardMenu = dashboard(user)
  // if (dashboardMenu) {
  //   _children.push(dashboardMenu[0])
  // }

  let layananMenu = layanan(user)
  if (layananMenu) {
    _children.push(layananMenu[0])
  }

  let usulanLayanannMenu = usulanLayanan(user)
  if (usulanLayanannMenu) {
    _children.push(usulanLayanannMenu[0])
  }

  let disposisiLayananMenu = disposisiLayanan(user)
  if (disposisiLayananMenu) {
    _children.push(disposisiLayananMenu[0])
  }

  let verifikasiLayananMenu = verifikasiLayanan(user)
  if (verifikasiLayananMenu) {
    _children.push(verifikasiLayananMenu[0])
  }

  // HIDE menu task https://gitlab.com/nusateknoglobal/purwakarta-project/pojok-aparatur/layanan-be/-/issues/3
  // let laporanMenu = laporan(user)
  // if (laporanMenu) {
  //   _children.push(laporanMenu[0])
  // }

  let pengesahanLayananMenu = pengesahanLayanan(user)
  if (pengesahanLayananMenu) {
    _children.push(pengesahanLayananMenu[0])
  }

  let rekapitulasiLayananMenu = rekapitulasiLayanan(user)
  if (rekapitulasiLayananMenu) {
    _children.push(rekapitulasiLayananMenu[0])
  }

  let masterMenu = isSuperAdmin(user)
  if (masterMenu) {
    // _children.push(dashboard)
    _children.push(masterMenu[0])
  }


  return [
    {
      _name: "CSidebarNav",
      _children: _children
    }
  ]
}

export default { menu };
