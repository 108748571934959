<template>
  <CSidebar
    fixed
    style="
      background-color: #204271;
      background-image: linear-gradient(315deg, #204271 0%, #142d58 74%);
    "
    :minimize="minimize"
    :show="show"
    @update:show="
      (value) => $store.commit('responsive/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="/img/logo-pojok-aparatur-bkpsdm-pwk.png"
        class="c-sidebar-brand-full c-icon c-icon-custom-size"
      />
      <!-- <span>{{ username }}</span> -->
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 556 134"
        role="img"
        class="c-sidebar-brand-full c-icon c-icon-custom-size"
        height="35"
      >
        <g
          transform="translate(0.000000,35.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        />
      </svg>-->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav" :key="renderControl" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="
        $store.commit('responsive/set', ['sidebarMinimize', !minimize])
      "
    />
  </CSidebar>
</template>

<script>
import navs from "./_nav";
import { mapGetters } from "vuex";
export default {
  name: "TheSidebar",

  data() {
    return {
      renderControl: 0,
      nav: [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: "Dashboard",
              to: "/dashboard",
              icon: "cil-speedometer",
              badge: {
                color: "primary",
                text: "NEW",
              },
            },
          ],
        },
      ],
    };
  },
  watch: {
    user(new_user) {
      this.setNav(new_user);
    },
  },
  mounted() {
    this.setNav(this.user);
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
    show() {
      return this.$store.state.responsive.sidebarShow;
    },
    minimize() {
      return this.$store.state.responsive.sidebarMinimize;
    },
  },
  methods: {
    async setNav(new_user) {
      let new_nav = await navs.menu(new_user);
      this.nav = new_nav;
    },
  },
};
</script>

<style>
.c-sidebar-brand-full {
  width: 100% !important;
  height: 46px !important;
}
</style>